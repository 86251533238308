import { useStaticQuery, graphql } from "gatsby"

export const useAllCreditCard = () => {
  const { creditCards } = useStaticQuery(
    graphql`
      query AllCreditCards {
        creditCards: allPrismicCreditCard(
          sort: {
            fields: data___bank___uid, 
            order: ASC
          }
          filter: {
            data: {
              display_on_offer_page: {
                eq: true
              },
            }
          }
          ) {
          nodes {
            uid
            prismicId
            data {
              name
              card_type
              display_on_offer_page
              display
              card_image {
                alt
                url
                dimensions {
                  width
                  height
                }
                fixed(height: 52){
                  ...GatsbyPrismicImageFixed_noBase64
                }
              }
              bank {
                document {
                  ... on PrismicBank {
                    uid
                    prismicId
                    data {
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return creditCards.nodes
}

export default useAllCreditCard
